<template>
  <div id="app">
    <div class="header">
      <div class="sensors">
        <center>
          <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">
          <img v-if="bbActive" src="/assets/buttons/list-button-diap.png" alt="Subject Buttons" @click="showBB">
          <img v-if="groupsActive" src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">
        </center>
      </div>
        <img v-if="langId !== 'x'" class="flag" :src="'/assets/buttons/flags/'+langId+'_small.jpg'" @click="showHome">
        <img v-if="langId === 'x'" class="flag" :src="'/assets/buttons/flags/'+languageX+'_small.jpg'" @click="showHome">
      <div class="home"><img src="/assets/buttons/home-wit-schaduw.png" alt="home" @click="showWelcome"></div>
      <div class="divider"><center><hr></center></div>
    </div>
    <div class="objectlist-container">
    </div>
    <center>
        <h1 class="audio-code" style="margin-top: 110px;">{{ codeToSend }}</h1>
        <div class="keyboard-container">
            <div class="key" @click="press('1')"><span>1</span></div>
            <div class="key" @click="press('2')"><span>2</span></div>
            <div class="key" @click="press('3')"><span>3</span></div>
            <div class="key" @click="press('4')"><span>4</span></div>
            <div class="key" @click="press('5')"><span>5</span></div>
            <div class="key" @click="press('6')"><span>6</span></div>
            <div class="key" @click="press('7')"><span>7</span></div>
            <div class="key" @click="press('8')"><span>8</span></div>
            <div class="key" @click="press('9')"><span>9</span></div>
            <div class="key-del" @click="press('-')"><span>&#x232B;</span></div>
            <div class="key" @click="press('0')"><span>0</span></div>
            <div class="key-go" @click="press('+')"><span>&#x25BA;</span></div>
        </div>
    </center>
    <div class="footer">
      <hr>
      <div class="copy-right">&copy; Easycast Audio Tour</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import router from '../router';
// import configData from '../../public/config/app.json';
var codeToSend = '00';
export default {
  name: 'KeyPad',
  components: {
  },
  props: ['langId'],
  data() {
    return {
        codeToSend,
        apiUrl: null,
        apiKey: null,
        apiCollection: null,
        qrActive: false,
      bbActive: false,
      groupsActive: false,
        languageX: '',
        twoNumbers: true
    }
  },
  methods: {
    showHome: function() {
      router.push('/');
    },
    showWelcome: function() {
      router.push('/welcome/' + this.langId);
    },
    showIntro: function() {
      router.push('/intro/' + this.langId);
    },
    showQR: function() {
      router.push('/qr/'+this.langId);
    },
    showBB: function() {
      router.push('/buttons/'+this.langId);
    },
    showGroups: function() {
      router.push('/groups/'+this.langId);
    },
    handleCode(codeIn) {
        if(!this.twoNumbers) {
            if(parseInt(codeIn) < 10) {
                codeIn = '0' + parseInt(codeIn);
                // console.log('handleCode codeIn: ' + )
            }
        }
        const codeSearch = 'audio-' + codeIn;
        console.log('axios query: ' + this.apiUrl + 'items?tags='+codeSearch+'&collection=' + this.apiCollection + '&key=' + this.apiKey);
        axios.get(this.apiUrl + 'items?tags='+codeSearch+'&collection=' + this.apiCollection + '&key=' + this.apiKey)
          .then((res) => {
            var objectId = res.data[0].id;
            router.push('/object/'+this.langId+'/'+objectId);
            }
          )
          .catch(err => console.log(err));
    },
    press(keyIn) {
        // console.log(keyIn);
        if(keyIn === '+') {
            console.log('gaan!');
            if(this.twoNumbers) {
                if(this.codeToSend !== '00' && this.codeToSend.length === 2) {
                    this.handleCode(this.codeToSend);
                }
            } else {
                if(this.codeToSend !== '00' && this.codeToSend !== '0' && this.codeToSend !== '') {
                    this.handleCode(this.codeToSend);
                }
            }
        }
        else if(keyIn === '-') {
            console.log('wis!');
            if(this.codeToSend.length <= 1) {
                this.codeToSend = '00';
            } else {
                this.codeToSend = this.codeToSend.slice(0,-1);
            }
        } else {
            if(this.codeToSend === '00') {
                this.codeToSend = keyIn;
            } else {
                if(this.codeToSend.length < 2) {
                this.codeToSend = this.codeToSend + keyIn;
                }
            }
        }
    }
  },
  created() {
      axios.get('/config/app.json')
      .then((res) => {
          let configData = res.data;
          this.qrActive = configData.qrscanner;
        this.bbActive = configData.buttonboard;
        this.groupsActive = configData.groups;
          this.apiUrl = configData.api;
          this.apiKey = configData.api_key;
          this.apiCollection = configData.api_collection;
          this.languageX = configData.language_x;
          this.twoNumbers = configData.twonumbers;
      })
      .catch((err) => { console.log('error config: ' + err) });
  }
}
</script>

<style>
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    font-family: 'robotoregular', Arial, Helvetica, sans-serif;
    line-height: 1.4;
  }
  .header .home {
    position: absolute;
    padding: 5px;
    height: 60px;
    top: 0px;
    left: 5%;
  }
  .header .home img {
    height: 60px;
    width: auto;
  }
  .header .sensors {
    position: absolute;
    padding: 5px;
    height: 60px;
    width: 100%;
    top: 0px;
    /* align-items: center; */
  }
  .header .sensors img {
    height: 60px;
    width: auto;
    margin-right: 30px;
  }
  .header .flag {
    position: absolute;
    right: 5%;
    top: 5px;
    width: auto;
    height: 60px;
  }

  .header .qr {
    position: absolute;
    padding: 5px;
    height: 60px;
    width: 100%;
    top: 0px;
    align-items: center;
  }
  .header .qr img {
    height: 60px;
    width: auto;
  }
.keyboard-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%;
    max-width: 600px;
}
.keyboard-container .key {
    display:inline-flex;
    flex: 0 0 30%;
    /* padding: 10px; */
    font-family: 'robotobold', 'Courier New', Courier, monospace;
    font-size: 25px;
    font-weight: bold;
    /* width: 33%; */
    height: 60px;
    background-color: white;
    color: black;
    text-align: center;
    vertical-align: middle;
    margin: 5px;
    border-radius: 5px;
}
.keyboard-container .key-go {
    display:inline-flex;
    flex: 0 0 30%;
    /* padding: 10px; */
    font-family: 'robotobold', 'Courier New', Courier, monospace;
    font-size: 24px;
    font-weight: bold;
    /* width: 33%; */
    height: 60px;
    background-color: green;
    color: white;
    text-align: center;
    vertical-align: middle;
    margin: 5px;
    border-radius: 5px;
}
.keyboard-container .key-del {
    display:inline-flex;
    flex: 0 0 30%;
    /* padding: 10px; */
    font-family: 'robotobold', 'Courier New', Courier, monospace;
    font-size: 25px;
    font-weight: bold;
    /* width: 33%; */
    height: 60px;
    background-color: red;
    color: white;
    text-align: center;
    vertical-align: middle;
    margin: 5px;
    border-radius: 5px;
}

.keyboard-container .key span,
.keyboard-container .key-del span,
.keyboard-container .key-go span {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 15px;
}
  .objectlist-container {
    position: absolute;
    top: 120px;
    color: white;
  }
  .audio-code {
          margin-top: 200px;
          margin-bottom: 10px;
          color: white;
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    color: white;
    text-align: center;
    background-color: black;
  }
  .copy-right {
    padding-top: 6px;
    padding-bottom: 6px;
  }
</style>
